<template src="./NewOvi.html">
</template>

<script>

import coursesService from "@/services/Courses";
import UploadImageModal from "@/components/UploadImageModal/UploadImageModal";
import ovisService from "@/services/Ovis";
import { putFile } from "@/services/files";

export default {
  name: "NewOvi",
  components: {
    UploadImageModal
  },
  data() {
    return {
      title: '',
      allGeneralCourses: [],
      searchAllCourses: [],
      searchCourse: '',
      radioSelection: null,
      module: null,
      step: 1,
      oviTemplates: [],
      oviTemplateInfo: [],
      ovisType: null,
      nameFile: null,
    }
  },
  methods: {
    async next() {
      if (this.$route.query.id) {
        const data = {
          name: this.title,
          courseId: this.radioSelection.id.toString(),
          moduleCourse: this.module,
          ovisType: this.ovisType,
          nameFile: this.nameFile,
          url: this.url
        }
        this.$store.dispatch("fetchOvi", data);
        await this.$router.push(`/ovis?id=${this.$route.query.id}`);
      } else {
        if (this.step === 1) {
          this.step = 2
        } else if (this.step === 3) {
          this.createOvi(this.oviTemplateInfo);
        }
      }
    },
    async getOvisTemplates() {
      try {
        this.oviTemplates = await coursesService.getOvisTemplates();
      } catch (e) {
        console.error(e);
      }
    },
    async getOvisTemplate(id) {
      try {
        this.oviTemplateInfo = await coursesService.getOvisTemplate(id);
        this.step = 3;
      } catch (e) {
        console.error(e);
      }
    },
    async createOvi(dataTemplate) {
      const nameFile = `${Date.now()}.json`;
      const blob = new Blob([JSON.stringify(dataTemplate.dataTemplate, null, 2)], {
        type: "application/json",
      });
      await putFile(blob, "Ovis-process", nameFile);
      const data = {
        name: this.title,
        courseId: this.radioSelection.id.toString(),
        moduleCourse: this.module,
        ovisType: dataTemplate.name,
        nameFile: nameFile
      }
      const oviData = await ovisService.createOvis(data);
      this.$store.dispatch("fetchOvi", null);
      await this.$router.push(`/ovis?id=${oviData.data.id}`);
    },
    async getAllGeneralCourses() {
      try {
        this.allGeneralCourses = await coursesService.getAllMyCourses();
        this.searchAllCourses = this.allGeneralCourses;
        if (this.$route.query.id) {
          this.getOviData(this.$route.query.id);
        }
      } catch (e) {
        console.error(e);
      }
    },
    async getOviData(id) {
      try {
        const response = (await ovisService.getOvis(id));
        this.title = response.data.name;
        this.radioSelection = this.allGeneralCourses.find((course) => {
          if (course.id == response.data.courseId) {
            return true;
          }
        });
        this.module = response.data.moduleCourse;
        this.nameFile = response.data.nameFile;
        this.ovisType = response.data.ovisType;
        this.url = response.url;
      } catch (e) {
        console.error(e);
      }
    },
  },
  mounted() {
    this.getAllGeneralCourses();
    this.getOvisTemplates();
  },
  watch: {
    searchCourse() {
      if (this.searchCourse.length > 0) {
        this.searchAllCourses = this.allGeneralCourses.filter(
          (inst) => {
            if (inst.codigo === parseInt(this.searchCourse)) {
              return true;
            } else if (inst.name.toLowerCase().includes(this.searchCourse.toLowerCase())) {
              return true;
            } else if (inst.program.toLowerCase().includes(this.searchCourse.toLowerCase())) {
              return true;
            } else if (inst.institution.toLowerCase().includes(this.searchCourse.toLowerCase())) {
              return true;
            } else {
              return false;
            }
          }
        );
      } else {
        this.searchAllCourses = this.allGeneralCourses;
      }
    },
  },
  computed: {
    allCourses() {
      return this.searchAllCourses
    },
  }
}
</script>

<style scoped lang="scss" src="./NewOvi.scss">

</style>
